import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { distinctUntilChanged } from 'rxjs/operators';
import { AmgcaService } from 'src/app/services/amgca/amgca.service';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { Equipment } from 'src/app/utils/model/equipment.model';
import { DialogComponent } from '../../dialog/dialog.component';
import { EquipmentDisplayComponent } from '../../display/equipment-display/equipment-display.component';
import { CommunicationType } from '../../model/communication-type.model';
import { Fare } from '../../model/fare.model';
import { RefProductRequirement } from '../../model/ref_product_requirement.model';
import { productsValidator } from '../../validators/products.validator';
import { RadioFormComponent } from '../radio-form/radio-form.component';
import { Product } from '../../model/product.model';
import { RefProductIncompatibility } from '../../model/ref_product_incompatibility.model';
import {Software} from '../../model/software.model';

@Component({
  selector: 'app-equipments-form',
  templateUrl: './equipments-form.component.html',
  styleUrls: ['./equipments-form.component.scss']
})
export class EquipmentsFormComponent implements OnInit {

  constructor(private formUtilsService: FormUtilsService, private backendService: BackendService, private dialog: MatDialog, private amgcaService: AmgcaService) {

  }
  @Input()
  get formArray(): FormArray {
      return this.form;
  }
  set formArray(formArray: FormArray) {
      this.form = formArray;
  }
  form = this.formUtilsService.buildForm([]) as FormArray;

  equipmentForm: AbstractControl = null;

  @Input()
  stepper: MatStepper;

  @Input()
  refEquipments: Partial<Equipment>[] = [];

  @Input()
  inactiveRefEquipments: Partial<Equipment>[] = [];

  @Input()
  refProductRequirements: RefProductRequirement[] = [];

  @Input()
  refProductIncompatibilities: RefProductIncompatibility[] = [];

  customerEquipments: Equipment[] = [];

  displayedEquipments = this.refEquipments;

  iconMapping = {
    '4G': 'sim_card',
    '3G': 'sim_card',
    IP: 'wifi',
    RTC: 'local_phone',
    BLUETOOTH: 'bluetooth'
  };

  @Input()
  checkboxConfig = {
    'Mode de connexion': {
      parent: 'ref_communication_types',
      controls: ['communication_type'],
    },
    'Logiciel(s) compatible(s)': {
      parent: 'ref_softwares',
      controls: ['name'],
    },
    'Accessoire(s) compatible(s)': {
      parent: 'ref_accessories',
      controls: ['name'],
    }
  };

  @Input()
  radioConfig = {
    'Type de contrat': {
      function: Equipment.getContractTypeLabel,
      parent: 'ref_fares',
      controls: ['contract_type'],
      sort: ([ka, va], [kb, vb]) => ka.localeCompare(kb)
      // display: true
    },
    'Durée du contrat': {
      function: Equipment.getContractDurationLabel,
      parent: 'ref_fares',
      controls: ['contract_duration'],
      sort: ([ka, va], [kb, vb]) => kb.localeCompare(ka)
      // display: true
    },
    Prestation: {
      function: Equipment.getInstallationLabel,
      parent: 'ref_fares',
      controls: ['installation_mode'],
      // display: true
    },
    Mainteneur: {
      parent: 'ref_fares',
      controls: ['id_maintainer'],
      // display: true
    }
  };

  @Input()
  config = {
    quantity: 'Quantité',
    rental_equipment_amount: 'Total location (€ HT)',
    purchase_equipment_amount: 'Total achat (€ HT)',
    purchase_softwares_amount: 'Total logiciels (€ HT)',
    purchase_services_amount: 'Total accessoires (€ HT)'
  };

  displayRadio() {
    return Object.values(this.radioConfig).some(conf => Object.values(conf['options']).length>1);
  }

  getName(equipment: Equipment) {
    return Equipment.getName(equipment);
  }

  getContractLabel(equipment: Equipment) {
    return Equipment.getContractLabel(equipment);
  }

  getInstallationLabel(equipment: Equipment) {
    return Equipment.getInstallationLabel(equipment);
  }

  setSubscriptions(control: FormGroup) {
    control.get('softwares').setValidators([productsValidator]);
    control.get('softwares').updateValueAndValidity();
    control.get('quantity').valueChanges.subscribe(v => {
        control.get('purchase_softwares_amount').setValue((control.get('softwares') as FormArray).getRawValue().reduce((acc, val) => acc + val.fare * Number(!val.id_order), 0));

        control.get('purchase_services_amount').setValue((control.get('accessories') as FormArray).getRawValue().reduce((acc, val) => acc + val.fare * Number(!val.id_order) * val.quantity, 0));

        ['rental_equipment'].forEach(_ => {
            control.get(_ + '_total').setValue(control.get(_ + '_amount').value * control.get('quantity').value * Number(!control.get('id_order').value) * 1);
        });
        ['purchase_equipment', 'yearly_maintenance', 'yearly_subscription3g'].forEach(_ => {
            control.get(_ + '_total').setValue(control.get(_ + '_amount').value * control.get('quantity').value * Number(!control.get('id_order').value));
        });
        ['purchase_softwares'].forEach(_ => {
            control.get(_ + '_total').setValue(control.get(_ + '_amount').value * control.get('quantity').value);
        });
        ['purchase_services'].forEach(_ => {
            control.get(_ + '_total').setValue(control.get(_ + '_amount').value);
        });
        const softwares_total = control.get('softwares')['controls'].reduce((acc,val) => acc + (val.get('quantity')?.value ?? 1)*Number(!val.get('id_order').value),0);
        const services_total = control.get('accessories')['controls'].reduce((acc,val) => acc + (val.get('quantity')?.value ?? 1)*Number(!val.get('id_order').value),0);
        control.get('softwares_total').setValue(softwares_total);
        control.get('services_total').setValue(services_total);
        const rawValue = control.getRawValue();
        control.get('total').setValue(Object.keys(rawValue).filter(_ => _.includes('_total')).reduce((acc, val) => acc + rawValue[val], 0)-softwares_total-services_total);
    });
    ['softwares', 'accessories'].forEach(_ => {
      control.get(_).valueChanges.subscribe(v => {
        control.get('quantity').setValue(control.get('quantity').value);
      });
    });
    control.get('quantity').valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
      control.get('quantity').setValue(v, {onlySelf: true, emitEvent: false});
    });
    control.get('quantity').setValue(control.get('quantity').value);
    if(control.get('id_order').value) control.get('quantity').disable();
  }

  getChangeRequestPossibilities(equipment: Equipment, type) {
    const labels = Object.values(this.radioConfig).find(_ => _["controls"].includes(type))['options'];
    return this.hasPossibleChangeRequest(equipment).map(_ => _[type]).filter((v,i,a) => a.indexOf(v)===i).map(_ => Object.entries(labels).find(([key, value]) => value[type]==_)?.[0]?.toLowerCase()).filter(_ => _);
  }

  chooseEquipment(equipment: Equipment, modal = false) {
    if(modal && !this.form.length) {
        let config = {...this.radioConfig};
        const possibleChangeRequest = this.hasPossibleChangeRequest(equipment);
        const callback = () => {
            Object.values(config).forEach(conf => {
                conf['options'] = Object.entries(conf['options'])
                .filter(([k,v]) => possibleChangeRequest.some(possible => this.objectIncludes(v, possible.ref_fare)))
                .reduce((acc,[k,v]) => ({...acc, [k]:v}),{});
                if(Object.values(conf['options']).length == 1) (this.form.parent as FormGroup).patchValue(Object.values(conf['options'])[0], {emitEvent: false});
            });
        }
        const subscription = this.form.parent.valueChanges.subscribe(_ => {
            callback();
        });
        callback();
        this.filterChange();
        callback();
        const possibilities = this.getChangeRequestPossibilities(equipment, "installation_mode");
        this.dialog.open(DialogComponent, {
            disableClose: true,
            data: {
              components: [
                {
                  name: RadioFormComponent,
                  inputs: {
                    title: "Choix du contrat pour le changement de gamme",
                    content: possibilities.length > 1 ?`Ce commerçant peut choisir entre plusieurs types de prestations pour le changement de gamme, merci de faire votre choix :`:`La prestation initiale de service (${possibilities.join(" / ")}) est automatiquement reconduite dans la sélection.`,
                    form: this.form.parent,
                    radioConfig: config,
                    disabled: !!this.form.length
                  }
                }
              ],
              buttons: {
                'Valider': true
              }
            }
        }).afterClosed().subscribe(_ => {
            subscription.unsubscribe();
            this.filterChange();
            if(_) {
                const newEquipment = this.displayedEquipments.find(displayedEquipment => displayedEquipment.id_ref == equipment.id_ref) as Equipment;
                newEquipment.equipment_change_request = true;
                this.chooseEquipment(newEquipment)
            }
        });
        return;
    }

    window.scroll(0, 0);

    if(sessionStorage.getItem('amgca')) {
        const amgcaOrder = this.amgcaService.amgcaToOrder(JSON.parse(sessionStorage.getItem('amgca')), equipment.ref_softwares);
        const softwares = [];// amgcaOrder.suborders[0].equipments[0].softwares;
        equipment = new Equipment({...equipment, softwares});
    }
    else equipment = new Equipment(equipment);
    if (!equipment.communication_type) {
      equipment.ref_communication_type = new CommunicationType(equipment.ref_communication_types[0]);
      equipment.ref_communication_type.id = equipment.ref_communication_type?.id_ref;
      equipment.communication_type = equipment.ref_communication_type?.communication_type;
    }
    equipment.peripherals = equipment.ref_peripherals as any;
    const equipmentForm = this.formUtilsService.buildForm(equipment) as FormGroup;
    this.setSubscriptions(equipmentForm);
    this.form.push(equipmentForm);
    this.editEquipment(this.form.length - 1);
  }


  editEquipment(index) {
    this.equipmentForm = this.form.get([index]);
  }

  getEquipmentFormIndex() {
    return Number(this.formUtilsService.getFormControlName(this.equipmentForm));
  }

  addEquipment() {
    if (!this.equipmentForm.valid) {
      this.equipmentForm.markAllAsTouched();
      return;
    }
    if (this.form.parent.get('additional_order').value) {
      this.editEquipment((this.getEquipmentFormIndex() + 1) % this.form.length);
      if(!this.getEquipmentFormIndex()) this.stepper.next();
    }
    else {
      this.dialog.open(DialogComponent, {
        data: {
          content: `Equipement enregistré avec ses logiciels.
          Que souhaitez-vous faire ?`,
          buttons: {
            'Ajouter des matériels': true,
            'Finaliser la commande': false
          }
        }
      }).beforeClosed().subscribe(closed => {
        if (closed != null) {
          this.equipmentForm = null;
        }
        if (closed === false) {
          this.stepper.next();
        }
      });
      this.conditionalDisplayDccWarning();
    }
  }

  conditionalDisplayDccWarning(): void {
    const softwares: Software[] = this.equipmentForm.get('softwares').value;
    const dccId = 80;
    if (softwares.some(software => software.id_ref === dccId)) {
      this.dialog.open(DialogComponent, {
        data: {
          title: `L’activation du logiciel DCC nécessitant 5 jours ouvrés minimum, il est préconisé de transmettre au mainteneur le bon de commande signé, après ce délai`,
          buttons: {
            'Je comprends': true,
          }
        }
      });
    }
  }

  removeEquipment() {
    const index = this.getEquipmentFormIndex();
    this.equipmentForm = null;
    this.form.removeAt(index);
  }

  updateCustomerEquipments(customerForm) {
    if(customerForm.get('id_ref').value) {
        this.backendService.request('get', 'equipments',{params: {
          searchCriterias: JSON.stringify([
          {
            "logical": "and",
            "path": "suborders.order.customer.idRef",
            "operation": "equal",
            "value": customerForm.get('id_ref').value
          }
        ])}}).subscribe(equipments => {
            this.customerEquipments=equipments.map(equipment => Equipment.updateEquipment({...equipment, ...this.inactiveRefEquipments.find(_ => _.id_ref==equipment.id_ref)}));
        });
    }
  }

  changeRequestFilter = ["id_maintainer"]; //["contract_type","contract_duration","installation_mode","id_maintainer"];

  isChangeRequestEnabled(availableEquipment) {
    return !this.form.parent.get('additional_order').value && this.inactiveRefEquipments.find(equipment => ["id_ref"].every(_ => availableEquipment[_] == equipment[_])).ref_fares.some(ref_fare => ref_fare?.change_request_enabled);
  }

  countChangeRequestLeft() {
    return Math.max(0,Equipment.filterInstalled(this.customerEquipments).reduce((acc,val) => acc+(val.quantity||1)*(val.equipment_change_request?1:1),0) -
    this.form.controls.filter(control => control !== this.equipmentForm).map(control => (control as FormGroup).getRawValue()).reduce((acc,val) => acc+(val.quantity||1)*(val.equipment_change_request?1:0),0));
  }

  hasChangeRequest(availableEquipment) {
    if(!this.isChangeRequestEnabled(availableEquipment)) return [];
    return Equipment.filterInstalled(this.customerEquipments).filter(equipment =>
        equipment.ref_fare?.change_request_enabled &&
        this.changeRequestFilter.every(_ => availableEquipment[_] == equipment[_])
        // && ["id_ref"].some(_ => availableEquipment[_] != equipment[_])
    );
  }

  hasPossibleChangeRequest(availableEquipment) {
    const refEquipment = {...this.inactiveRefEquipments.find(equipment => ["id_ref"].every(_ => availableEquipment[_] == equipment[_]))};
    return refEquipment.ref_fares
    // .filter(ref_fare => this.changeRequestFilter.some(_ => availableEquipment[_] != ref_fare[_]))
    .map(ref_fare => ({...refEquipment, ...ref_fare, ref_fare, id_ref: refEquipment.id_ref}))
    .filter(_ => this.hasChangeRequest(_).length);
  }

  changeRequestText(availableEquipment) {
    const suborder = this.form.parent;
    if(suborder) {
        const value = suborder.getRawValue();
        const changeRequestLeft = this.countChangeRequestLeft();
        if(!changeRequestLeft) return null;
        const configs = this.hasChangeRequest(availableEquipment);
        if(configs.length) return null;
        const possible_configs = this.hasPossibleChangeRequest(availableEquipment);
        if(possible_configs.length) return "Disponible en "+possible_configs.map(_ => _.ref_fare).map(_ => `${Fare.getContractTypeLabel(_)} ${Fare.getContractDurationLabel(_)} ${Fare.getInstallationLabel(_)}`).filter((v,i,a) => a.indexOf(v)===i).join(", ");
    }
    return null;
  }

  changeRequestTooltip(equipment: Equipment) {
    const possibilities = this.getChangeRequestPossibilities(equipment, "installation_mode");
    return possibilities.length > 1 ?`Lors du changement de gamme, vous devrez choisir de type de contrat du nouveau TPE qui pourra être différent de celui d'origine`:`Il s'agit d'un changement de matériel sans pénalités. Le changement de prestation (${possibilities.join(" / ")}) est impossible.`;
  }

  ngOnInit(): void {
    const customerForm = this.form.root.get("customer");
    if(customerForm) {
        this.updateCustomerEquipments(customerForm);
        customerForm.get('id_ref').valueChanges.pipe(distinctUntilChanged()).subscribe(id_ref => {
            this.updateCustomerEquipments(customerForm);
        });
    }
    this.form.controls.forEach(equipmentForm => {
        this.setSubscriptions(equipmentForm as FormGroup)
    });
    if(this.form.controls.length) {
        Object.values(this.radioConfig).map(_ => _.controls).reduce((acc,val) => [...acc,...val],[]).forEach(control => {
            this.form.parent.get(control).setValue(this.form.get("0").get(control).value);
        });
    }

    this.initRef();

    this.form.controls.forEach(equipmentForm => {
        const foundEquipment = this.displayedEquipments.find(_ => equipmentForm.get('id_ref').value == _.id_ref);
        if(foundEquipment) {
          this.formUtilsService.patchForm(equipmentForm,Object.keys(foundEquipment).reduce((acc,val)=>{
            if((Array.isArray(foundEquipment[val]) && foundEquipment[val].length) || (!Array.isArray(foundEquipment[val]) && foundEquipment[val])){
              acc[val] = foundEquipment[val];
            }
            return acc;
          },{}));
        }
    });
    if(this.form.controls.length) this.editEquipment(0);

    this.form.parent.get('additional_order').setValue(this.form.parent.get('additional_order').value);
    this.initRef();
    if(sessionStorage.getItem('amgca')){
      const refSoftwares = this.refEquipments.reduce((acc,val) =>[...acc, ...val.ref_softwares],[]).map(_ => JSON.stringify(_)).filter((v,i,a) => a.indexOf(v)===i).map(_ => JSON.parse(_));
      const amgcaOrder = this.amgcaService.amgcaToOrder(JSON.parse(sessionStorage.getItem('amgca')), refSoftwares);
      if(false) {
        Object.entries(this.checkboxConfig).forEach(([key, config]) => {
          let entity = amgcaOrder.suborders[0].equipments[0];
          if(config.parent) {
            entity = entity[config.parent.replace(/^ref_/,'')];
          }
          if(entity) {
            config.controls.forEach(control => {
              if(Array.isArray(entity)) {
                entity.forEach(_ => {
                  if(this.checkboxConfig[key]['options'][_[control]] !== undefined) {
                    this.checkboxConfig[key]['options'][_[control]] = true;
                  }
                });
              } else {
                if(this.checkboxConfig[key]['options'][entity[control]] !== undefined) {
                  this.checkboxConfig[key]['options'][entity[control]] = true;
                }
              }
            })
          }
        });
      }
      this.filterChange();
    }

  }

  initRef() {
    this.displayedEquipments = this.refEquipments;
    Object.keys(this.checkboxConfig).forEach(k => {
      let refEquipments = this.refEquipments;
      if (!this.checkboxConfig[k].function) {
        this.checkboxConfig[k].function = _ => this.checkboxConfig[k].controls.map(control => _[control]).join(' ');
      }
      if (this.checkboxConfig[k].parent) {
        refEquipments = [].concat(...Object.values(refEquipments).map(_ => _[this.checkboxConfig[k].parent]));
      }
      this.checkboxConfig[k].options = refEquipments.reduce((acc, val) => {
        if (this.checkboxConfig[k]) {
          acc[this.checkboxConfig[k].function(val)] = false;
        }
        return acc;
      }, {});
    });
    Object.keys(this.radioConfig).forEach(k => {
      if (!this.radioConfig[k].function) {
        this.radioConfig[k].function = _ => this.radioConfig[k].controls.map(control => _[control]).join(' ');
      }
    });
    [].concat(...Object.values(this.radioConfig).map(_ => _.controls)).forEach(control => {
      this.form.parent.get(control).valueChanges.subscribe(v => {
        let bool=false;
        let arr = [];
        let obj = {};
        Object.keys(this.radioConfig).reverse().forEach(k => {
          this.radioConfig[k].controls.forEach(c => {
            if(c==control) bool=true;
            if(!bool) obj[c] = null;
          });
          if(bool) arr.push(k);
        });
        this.form.parent.get(control).setValue(v,{emitEvent: false});
        this.filterChange(obj);
      });
    });
    this.filterChange();
    const defaultValue = {
      contract_type: 'rental',
      contract_duration: 48
    };
    Object.entries(defaultValue).forEach(([k, v]) => {
      const val = {[k]:v};
      if(Object.values(this.radioConfig).map(conf => Object.values(conf['options'])).some(options => options.some(option => this.objectIncludes(val,option)))) {
        (this.form.parent as FormGroup).patchValue(val);
      }
    });
  }

  filterChange(skip=null) {
    if(skip === null) {
      skip = Object.values(this.radioConfig).map(_ => _.controls).reduce((acc,val) => [...acc,...val],[]).reverse().reduce((acc,val) => ({...acc,[val]:null}),{});
    }
    const parent = this.form.parent as FormGroup;
    Object.keys(this.radioConfig).filter(_=>!this.radioConfig[_].controls.filter(c => !Object.keys(skip).includes(c)).length).forEach(k => {
      let refEquipments = this.refEquipments;
      if (this.radioConfig[k].parent) {
        refEquipments = [].concat(...Object.values(refEquipments).map(_ => _[this.radioConfig[k].parent]));
      }
      const parentValue = {...parent.value, ...skip};
      this.radioConfig[k].options = refEquipments.reduce((acc, val) => {
        const option = this.radioConfig[k].controls.reduce((a, v) => {
          if(Object.values(this.radioConfig).every(config => {
            return this.objectIncludes(this.objectSubset(parentValue, config.controls),val,true);
          })) {
            a[v] = val[v];
          }
          return a;
        }, {});
        if(Object.keys(option).length) {
          acc[this.radioConfig[k].function(val)] = option;
        }
        return acc;
      }, {});
      if(this.radioConfig[k].sort) {
        this.radioConfig[k].options = Object.entries(this.radioConfig[k].options).sort(this.radioConfig[k].sort).reduce((acc, [k, v]) => ({...acc, [k]: v}), {});
      }
      const option: Object = Object.values(this.radioConfig[k].options).find(_=> this.objectIncludes(_,parent.value));
      const newOption: Object = Object.values(this.radioConfig[k].options)[0] || {};
      if(!option) {
        parent.patchValue(newOption,{emitEvent: false});
      }
      skip = {...skip,...(option || newOption)};
    });

    this.displayedEquipments = JSON.parse(JSON.stringify(this.refEquipments))
    .filter(v => {
      return Object.values(this.checkboxConfig).every((config: any) => {
        const options = Object.keys(config.options || {}).filter(k => config.options[k]);
        let ins = config.parent ? v[config.parent] : v;
        if(!Array.isArray(ins)) ins = [ins];
        return options.every(option => ins.some(_ => config.function(_) == option));
      });
    })
    .filter((v, i, a) => {
      return Object.values(this.radioConfig).every((config: any) => {
        const key = config.function(this.form.parent.getRawValue());
        if (!key) {
          return true;
        }
        const options = [key];
        let ins = config.parent ? v[config.parent] : v;
        if(!Array.isArray(ins)) ins = [ins];
        ins = ins.filter(_ => options.every(option => config.function(_) == option));
        if (config.parent) {
          a[i][config.parent] = ins;
        }
        if (ins.length) {
          const {id, id_ref, ...body} = ins[0];
          a[i] = Object.assign(a[i], body);
        }
        return options.every(option => ins.some(_ => config.function(_) == option));
      });
    }).map(equipment=>new Equipment({
      ...equipment,
      ref_fare: {...equipment.ref_fares[0],id: equipment.ref_fares[0].id_ref},
      ref_fares: this.refEquipments.find(refEquipment => refEquipment.id_ref == equipment.id_ref).ref_fares
    }));
  }

  objectIncludes(small, big, allowSmallNull=false, allowBigNull = false) {
    return Object.keys(small).every(k => small[k]==big[k] || (allowSmallNull && !small[k]) || (allowBigNull && !big[k]));
  }

  objectSubset(object, keys) {
    return keys.reduce((a, c) => ({ ...a, [c]: object[c] }), {});
  }

  nosort() {}

  matchesRequirement(availableEquipment: Equipment) {
    return RefProductRequirement.matchesRequirement(availableEquipment, [
      ...this.customerEquipments,
      // ...this.form.getRawValue()
    ], this.refProductRequirements);
  }

  requirementText(availableEquipment: Equipment) {
    const unmatchedRequirements = this.matchesRequirement(availableEquipment);
    if(!unmatchedRequirements.length) return null;
    const translations = {
        accessories: "accessoire",
        interventions: "intervention",
        peripherals: "périphérique",
        services: "service",
        softwares: "logiciel",
    }
    return `La commande du ${availableEquipment?.name} sera possible après votre commande ` +
    this.matchesRequirement(availableEquipment)
    .map(equipment => {
        const {id_ref, ...products_list} = equipment;
        const refEquipment = this.inactiveRefEquipments.find(_ => _.id_ref == id_ref);
        return ` de ${refEquipment?refEquipment?.name:" TPE"} avec` +
        Object.entries(products_list).map(([k,products]: [k: string, products: Product[]]) => {
            return (products.length>1?` les ${translations[k]}s `:` le ${translations[k]} `)+
            products.map(product => {
                const refProduct = (refEquipment ? refEquipment["ref_"+k] : this.inactiveRefEquipments.reduce((acc,val) => [...acc,...val["ref_"+k]],[])).find(_ => _.id_ref == product.id_ref);
                return `${refProduct?.name}`
            }).join(" et ")
        }).join(" et ");
    })
    .join(" et ");
  }

  editChangeRequest(equipment_change_request) {
    if(this.equipmentForm) {
      this.equipmentForm.get('equipment_change_request').setValue(equipment_change_request);
      this.dialog.open(DialogComponent, {
        data: {
          title: "INFORMATION",
          content: `<div class="d-flex flex-column align-items-center">
          <span>Vous avez ${equipment_change_request?"modifié":"annulé"} le changement de gamme.</span><br>
          <span class="color-warn"><b>Note</b> : Le commerçant ${equipment_change_request?"changera de gamme":"sera équipé d'un matériel supplémentaire en conservant l'ancien"}. Souhaitez-vous continuer ?</span>
          </div>`,
          buttons: {
            "Oui": true,
            "Non": false
          }
        }
      }).beforeClosed().subscribe(_ => {
        if(!_) {
          this.equipmentForm.get('equipment_change_request').setValue(!equipment_change_request);
        }
      });
    }
  }

  displayEquipment(availableEquipment: Partial<Equipment>) {
    this.dialog.open(DialogComponent, {
      data: {
        components: [
          {
            name: EquipmentDisplayComponent,
            inputs: {
              equipment: availableEquipment,
            },
          }
        ]
      }
    });
  }

}
