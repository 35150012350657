import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {EMPTY} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BackendService} from 'src/app/services/backend/backend.service';
import {FormUtilsService} from 'src/app/services/form-utils/form-utils.service';
import {DialogComponent} from '../../dialog/dialog.component';
import {FormFieldComponent} from '../../form/form-field/form-field.component';
import {Merchant} from '../../model/nepting/merchant.model';
import {User} from '../../model/nepting/user.model';
import {LoginValidator} from '../../validators/login.validator';
import {ConfigService} from '../../../services/config/config.service';
import {Offer} from '../../model/offer.model';
import {FrenchPhoneValidator} from '../../validators/french-phone.validator';

@Component({
  selector: 'app-nepting-display',
  templateUrl: './nepting-display.component.html',
  styleUrls: ['./nepting-display.component.scss']
})
export class NeptingDisplayComponent implements OnInit {

  constructor(
    private backendService: BackendService,
    private dialog: MatDialog,
    private formUtilsService: FormUtilsService,
    private loginValidator: LoginValidator,
    private frenchPhoneValidator: FrenchPhoneValidator,
    private configService: ConfigService,
  ) {
  }

  @Input()
  id = null;

  @Input()
  refOffers: Offer[] = null;

  loading = false;

  merchants: Merchant[] = [];

  @Input()
  config = [
    {
      label: 'Utilisateurs',
      key: 'user',
      bool: 'is_merchant_user_active',
      actions: {
        null: 'RemoveMerchantUser',
        true: 'DeactivateMerchantUser',
        false: 'ActivateMerchantUser'
      },
      headers: [
        'Email',
        'Login',
        'Phone',
      ],
      values: [
        ...((this.merchants?.length > 1) ? ['merchant_id'] : []),
        'mail',
        'new_user_id',
        'login_phone',
      ],
      create: {
        action: 'AddMerchantUser',
        activated: (merchant: Merchant) => this.isCreationPossibleForMerchant(merchant),
        callback: (conf) => {
          const value = conf.form.getRawValue();
          conf.form.patchValue({
            email_address: value.mail,
            new_user_id: value.login,
            login: value.login,
            login_phone: value.login_phone,
          });
          conf.form.removeControl('merchant');
          conf.form.removeControl('store');
        },
        form: this.formUtilsService.buildForm(new User()),
        config: [
          {
            formControlName: 'mail',
            label: 'Adresse mail',
            type: 'email',
            required: true,
            pattern: /\S*/,
            maxLength: 80,
            asyncValidators: [this.backendService.backendValidator(_ => 'get', _ => 'nepting/users', control => ({
              params: {
                searchCriterias: JSON.stringify([
                  {
                    logical: 'and',
                    modifier: null,
                    path: 'merchantId',
                    operation: 'equal',
                    value: control?.parent?.get('merchant_id')?.value
                  },
                  {
                    logical: 'and',
                    modifier: null,
                    path: 'userId',
                    operation: 'equal',
                    value: control?.value
                  }
                ])
              }
            })).bind(this.backendService)]
          },
          {
            formControlName: 'login',
            label: 'Login',
            required: true,
            pattern: /[0-9a-z_]+/,
            updateOn: 'blur',
            maxLength: 64,
            asyncValidators: [this.loginValidator.validate.bind(this.loginValidator)],
          },
          {
            formControlName: 'login_phone',
            label: 'Téléphone',
            type: 'tel',
            required: true,
            pattern: /[0-9]{10}/,
            minLength: 10,
            maxLength: 10,
            validators: [this.frenchPhoneValidator],
          },
        ]
      }
    },
    {
      label: 'Contrats',
      key: 'application',
      bool: 'is_merchant_active',
      actions: {
        true: 'DeactivateMerchantApplication',
        false: 'ActivateMerchantApplication'
      },
      headers: [
        'Id marchant',
        'Contrat',
        'Ligne plaque',
        'Numero',
      ],
      values: [
        ...((this.merchants?.length > 1) ? ['merchant_id'] : []),
        'merchant_id',
        'softwares.0.name',
        'host_merchant_name',
        'softwares.0.contract_reference',
      ],
    }
  ];

  ngOnInit(): void {
    const idOffer = sessionStorage.getItem('idOffer');
    this.backendService.request('get', `nepting/merchants`, {
      params: {
        searchCriterias: JSON.stringify(
          [
            {
              logical: 'and',
              path: 'refCustomer.id',
              operation: 'equal',
              value: this.id
            },
            ...(idOffer ? [
              {
                logical: 'and',
                path: 'application.softwares.equipment.suborders.idOffer',
                operation: 'equal',
                value: idOffer
              }
            ] : [])
          ]
        )
      }
    }).subscribe((merchants: Merchant[]) => this.merchants = merchants
      .map(merchant => {
        this.loginValidator.setIdMerchant(merchant.merchant_id);
        delete merchant.store;
        return merchant;
      })
    );
  }

  getUserOppositeAction(isMerchantUserActive: boolean): 'RemoveMerchantUser' | 'DeactivateMerchantUser' | 'ActivateMerchantUser' {
    if (isMerchantUserActive == null) {
      return 'RemoveMerchantUser';
    }
    return isMerchantUserActive ? 'DeactivateMerchantUser' : 'ActivateMerchantUser';
  }

  getApplicationOppositeAction(isMerchantActive: boolean): 'DeactivateMerchantApplication' | 'ActivateMerchantApplication' {
    return isMerchantActive ? 'DeactivateMerchantApplication' : 'ActivateMerchantApplication';
  }

  getOppositeLabel(isMerchantActive: boolean): 'Supprimer' | 'Désactiver' | 'Activer' {
    if (isMerchantActive == null) {
      return 'Supprimer';
    }
    return isMerchantActive ? 'Désactiver' : 'Activer';
  }

  filter(key: string, bool: string, value: boolean): Merchant[] {
    return this.merchants.reduce(
      (values, merchant) => [...values, ...merchant[key]
        .map(v => ({...merchant, ...v}))], []
    ).filter(val => val[bool] === value);
  }

  create(conf, merchant): void {
    conf.form.patchValue(merchant);
    this.dialog.open(DialogComponent, {
      data: {
        buttons: {
          Envoyer: true,
        },
        components: conf.config.map(c => ({
          name: FormFieldComponent,
          inputs: {
            form: conf.form,
            config: c
          }
        }))
      }
    }).beforeClosed().subscribe(_ => {
      if (!_) {
        return;
      }
      if (!conf.form.valid) {
        this.create(conf, merchant);
      } else {
        conf?.callback(conf);
        this.action(conf.action, conf.form.getRawValue());
      }
    });
  }

  /**
   * Creation should not be possible for offers with nepting_user to false
   * and thus merchant with merchantId with the offer's trigram
   */
  isCreationPossibleForMerchant(merchant: Merchant): boolean {
    const offer = this.refOffers.filter((refOffer) => merchant.merchant_id.includes(refOffer.trigram))?.[0];
    return offer?.nepting_user ?? true;
  }

  batchAction(soapAction: string, bodies: any[]) {
    if (!bodies?.length) {
      this.loading = false;
      if (!this.dialog.openDialogs.length) {
        window.location.reload();
      }
      return EMPTY;
    }
    this.loading = true;
    const body = this.snakeToCamel(bodies.shift());
    const idOrder = body?.softwares?.[body?.softwares?.length - 1]?.idOrder;
    const params = idOrder ? {idOrder} : {};
    return this.backendService.requestWithoutCatchError('post', `nepting/action/${soapAction}`, {body, params}).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error(err);
        this.dialog.closeAll();
        this.dialog.open(DialogComponent, {
          data: {
            buttons: {
              OK: null
            },
            title: `Une erreur s'est produite lors de l'action Nepting`,
          },
        });
        return this.batchAction(soapAction, bodies);
      }),
    ).subscribe(() => {
      this.batchAction(soapAction, bodies);
    });
  }

  action(soapAction: string, body: any) {
    return this.batchAction(soapAction, [body]);
  }

  merge(merchant: Merchant, value: any): Merchant & any {
    return {...merchant, ...value};
  }

  noData(key: string): boolean {
    return this.merchants.every(merchant => !merchant[key].length);
  }

  snakeToCamel(value: any): any {
    if (Array.isArray(value)) {
      return value.map(v => this.snakeToCamel(v));
    } else if (value && typeof value === 'object' && value.toString() === '[object Object]') {
      return Object.entries(value).reduce((acc, [k, v]) => ({
        ...acc, [k.replace(/(_[a-z])/ig, ($1) => {
          return $1.toUpperCase().replace('_', '');
        })]: this.snakeToCamel(v)
      }), {});
    } else {
      return value;
    }
  }

  getValue(object, key) {
    return key
      .replace(/\[([0-9]+)\]/g, '.$1')
      .split('.')
      .filter(_ => _)
      .reduce((o, i) => o != null ?
          o[Number(i) ? ((Number(i) + o.length) % o.length) : i] : o
        , object) || object[key];
  }

}
