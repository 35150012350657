import { Address } from './address.model';
import { BankAccount } from './bank-account.model';
import { Base } from './base.model';
import { Order } from './order.model';
import { Builder } from "./builder.model";
import { formatDate } from '@angular/common';

export class Customer extends Base {
    constructor(values: Partial<Customer> = {}) {
        super(values);
        Builder.build.bind(this)(values,{
          orders: _ => new Order(_)
        });
    }

    french_naf_code: string = null;
    mcc: string = null;
    store_registration_number: string = null;
    company_registration_number: string = null;
    company_name: string = null;
    store_name: string = null;
    customer_intracommunity_vat: string = null;
    phone: string = null;
    secondary_phone: string = null;
    orders_total: number = null;
    equipments_total: number = null;
    bank_account: BankAccount = new BankAccount();
    delivery_address: Address = new Address();
    billing_address: Address = new Address();
    fiscal_address: Address = new Address();
    store_address: Address = new Address();
    login: string = null;
    login_phone: string = null;

    civility: string = null;
    firstname: string = null;
    lastname: string = null;

    orders: Partial<Order> [] = [];
    french_naf_label: string = null;
    bnpp = true;
    contact_comment: string = null;
    id_customer_maintainer: string = null;

    static getDisplayedColumns(icon = null, checkbox = false) {
        let columns = {};
        if (checkbox) {
            columns = {
                ...columns,
                checkbox: {type: 'checkbox', color: 'accent', width: 5}
            };
        }
        columns = {
            ...columns,
            store_registration_number: {label: 'SIRET', width: 10},
            store_name: {label: 'Dénomination sociale', width: 20, class: 'font-weight-bold color-primary-200'},
            'ref_delivery_address.zip_code': {label: 'CP', width: 6},
            'ref_delivery_address.city_name': {label: 'Ville', width: 10},
            equipments_total: {label: 'Matériel(s) en service', width: 10},
            additional_orders: {label: 'Autre(s) service(s)', width: 7},
            payment_incidents: {label: 'Incident(s) règlement', width: 7},
            updated_date: {label: 'Date dernière action', mapping: _ => _?formatDate(_, "dd-MM-yyyy", "fr-FR"):_, width: 15},
            'ref_user.firstname': {label: 'Prénom', width: 5},
            'ref_user.lastname': {label: 'Nom', width: 5}
        };
        if (icon !== null) {
            columns = {
                ...columns,
                [icon]: {type: 'icon', width: 5}
            };
        }
        return columns;
    }

    static getFilteredColumns() {
        const displayedColumns = {
            ...Customer.getDisplayedColumns(),
            'customers.orders.suborders.contract_emoney.contract_reference': {label: 'Contrat monétique'},
            company_registration_number: {label: 'SIREN'}
        };
        return ['customers.orders.suborders.contract_emoney.contract_reference','company_registration_number','store_name']
        .reduce((acc,val) => ({...acc, [val]: displayedColumns[val]['label']}), {});
    }

    static canDelete(customer: Customer) {
        return !customer.orders_total;
    }

    canDelete() {
        return Customer.canDelete(this);
    }
}

export interface CustomerStore {
  company_name: string;
  store_name: string;
  store_registration_number: number;
  company_registration_number: number;
  french_ape_code: number;
  french_naf_code: number;
  store_address: {
    name: string;
    address_full: string;
  } & CustomerStoreAddress;
}

export interface CustomerStoreAddress {
  address: string;
  zip_code: string;
  city_name: string;
}
