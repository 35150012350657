import {AbstractControl, AsyncValidator, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {BackendService} from '../../services/backend/backend.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigService} from '../../services/config/config.service';
import {Offer} from '../model/offer.model';


@Injectable({ providedIn: 'root' })
export class LoginValidator implements AsyncValidator {
  constructor(private backendService: BackendService, private configService: ConfigService) {}

  loginRegexp: RegExp = /^([0-9a-z_])+$/;

  offer: Offer = null;
  refCustomerId: string = null;
  idMaintainer: string = null;
  idMerchant: string = null;

  setOffer(offer: any): void {
    this.offer = offer;
  }

  setRefCustomerId(refCustomerId: string): void {
    this.refCustomerId = refCustomerId;
  }

  setIdMaintainer(idMaintainer: string): void {
    this.idMaintainer = idMaintainer;
  }

  setIdMerchant(idMerchant: string): void {
    this.idMerchant = idMerchant;
  }

  validateRegex(control: AbstractControl): boolean {
    if (!control.value) {
      return null;
    }
    return this.loginRegexp.test(control.value.trim());
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null);
    }

    const options = {
      params: {
        login: control.value,
      },
    };

    return this.backendService.request('post', 'nepting/login/exist', options).pipe(
      map(doesLoginExist => {
        if (doesLoginExist) {
          return {[this.configService.config.validators.login.unique]: true};
        }
        return this.validateRegex(control) ? null : {[this.configService.config.validators.login.errorRegex]: true};
      })
    );
  }
}
