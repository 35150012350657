// Build information, automatically generated by `ng-info`
const build = {
    version: "1.8.10",
    timestamp: "Fri Nov 08 2024 15:34:13 GMT+0100 (GMT+01:00)",
    message: null,
    git: {
        user: null,
        branch: "stable",
        hash: "f44066",
        fullHash: "f4406671dcab8c7db31f95115a3987e08c4064f2"
    }
};

export default build;