<ng-container *ngIf="equipmentForm==null else equipmentConfig">
    <div class="row">
        <div class="col-3 opposite-background-color-foreground-base p-3">
            <h3 class="color-primary-200 pb-2 m-0">Aide au choix</h3>
            <div class="d-flex flex-column justify-content-between">
                <div class="d-flex flex-column" *ngFor="let conf of checkboxConfig | keyvalue: nosort">
                    <ng-container *ngIf="((conf.value.options || {}) | keys).length">
                        <mat-label class="mat-body-strong p-1">{{conf.key}}</mat-label>
                        <mat-checkbox *ngFor="let option of (conf.value.options || {}) | keyvalue: nosort; let index=index" [(ngModel)]="conf.value.options[option.key]" (change)="filterChange()">{{option.key}}</mat-checkbox>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col">
            <app-radio-form *ngIf="displayRadio()" title="Contrat" [form]="form.parent" [radioConfig]="radioConfig" [disabled]="!!form.length"></app-radio-form>

            <mat-grid-list cols="12" rowHeight="320px" gutterSize="15px">
                <ng-container *ngFor="let availableEquipment of displayedEquipments; let i=index">
                    <mat-grid-tile colspan="6" *ngIf="!availableEquipment.inactive" class="shadow">
                        <mat-card class="row">
                                <mat-card-header class="mt-2">
                                    <div class="col-auto p-0">
                                        <mat-icon *ngFor="let ref of availableEquipment.ref_communication_types" [matTooltip]="ref.communication_type" matTooltipClass="primary">{{iconMapping[ref.communication_type]}}</mat-icon>
                                    </div>

                                    <mat-card-title><h3 matTooltip="Fiche produit" matTooltipClass="primary" class="detail-link color-primary-700 m-0" (click)="displayEquipment(availableEquipment)">{{getName(availableEquipment)}}</h3></mat-card-title>
                                    <mat-card-subtitle class="mt-2 color-accent"><b>{{availableEquipment.installation_type}}</b></mat-card-subtitle>
                                </mat-card-header>
                                <mat-card-content class="d-flex justify-content-between">
                                    <div class="equipment-img h-100 w-25 d-flex align-items-center">
                                        <img matTooltip="Fiche produit" matTooltipClass="primary" class="detail-link" [src]="backendService.backendUrl+'img/equipment/'+availableEquipment.id_ref+'.png'" onerror="this.src='../../../assets/icons/TPE.png'" width="80" (click)="displayEquipment(availableEquipment)">
                                    </div>
                                    <div class="w-75 d-flex flex-column justify-content-between">
                                        <div class="d-flex flex-row justify-content-between">
                                            <div>
                                                <a matTooltip="Fiche produit" matTooltipClass="primary" (click)="displayEquipment(availableEquipment)"  class="detail-link color-warn-A100 mat-body-2"><h4>+ de détails</h4></a>
                                            </div>

                                            <div>
                                                <div *ngIf="availableEquipment.rental_equipment_amount"><span class="mat-h3">{{availableEquipment.rental_equipment_amount*1 | currency}}</span> HT/Mois</div>
                                                <div *ngIf="availableEquipment.purchase_equipment_amount"><span class="mat-h3">{{availableEquipment.purchase_equipment_amount+availableEquipment.yearly_maintenance_amount+availableEquipment.yearly_subscription3g_amount | currency}}</span> HT</div>
                                                <div [class.invisible]="!availableEquipment.yearly_maintenance_amount">Dont maintenance : <span class="mat-h3">{{availableEquipment.yearly_maintenance_amount | currency}}</span> HT</div>
                                                <div [class.invisible]="!availableEquipment.yearly_subscription3g_amount">Dont abonnement {{availableEquipment.communication_type}} : <span class="mat-h3">{{availableEquipment.yearly_subscription3g_amount | currency}}</span> HT</div>
                                            </div>
                                        </div>
                                        <div class="equipment-choices d-flex flex-column">
                                            <div class="d-flex align-items-center" *ngIf="availableEquipment.ref_communication_types.length">
                                                <h5>Mode de connexion</h5>
                                                <mat-radio-group class="equipments-button" (change)="availableEquipment.communication_type=$event.value">
                                                    <ng-container *ngFor="let ref of availableEquipment.ref_communication_types; let index=index">
                                                        <mat-radio-button class="ml-0 mr-2" [value]="ref.communication_type" [checked]="availableEquipment.communication_type==ref.communication_type">{{ref.communication_type}}</mat-radio-button>
                                                    </ng-container>
                                                </mat-radio-group>
                                            </div>
                                            <div class="d-flex align-items-center" [class.invisible]="!hasPossibleChangeRequest(availableEquipment).length">
                                                <h5>Changement de gamme</h5>
                                                <mat-slide-toggle class="px-3" (change)="availableEquipment.equipment_change_request=$event.checked" [checked]="availableEquipment.equipment_change_request" [disabled]="!countChangeRequestLeft() || (!!form.length && !hasChangeRequest(availableEquipment).length)"></mat-slide-toggle>
                                                <mat-icon color="primary" [matTooltip]="changeRequestTooltip(availableEquipment)" matTooltipClass="primary">help_outline</mat-icon>
                                            </div>
                                            <!-- {{changeRequestText(availableEquipment)}} -->
                                        </div>
                                    </div>
                                </mat-card-content>
                                <mat-card-actions class="d-flex justify-content-end" [matTooltip]="requirementText(availableEquipment)" matTooltipClass="primary">
                                    <button mat-raised-button [ngClass]="matchesRequirement(availableEquipment).length?null:'background-color-primary-50 opposite-color-foreground-base'" (click)="chooseEquipment(availableEquipment,availableEquipment.equipment_change_request)" [disabled]="!!matchesRequirement(availableEquipment).length" >Sélectionner</button>
                                </mat-card-actions>
                        </mat-card>
                    </mat-grid-tile>
                </ng-container>
            </mat-grid-list>
        </div>
    </div>
</ng-container>

<ng-template #equipmentConfig>
    <form class="row justify-content-between" [formGroup]="equipmentForm">
        <div class="col-12 d-flex justify-content-between align-items-center opposite-background-color-foreground-base shadow ">
            <div class="row p-3">
                <div class="col d-flex align-items-center">
                    <img [src]="backendService.backendUrl+'img/equipment/'+equipmentForm.get('id_ref').value+'.png'" onerror="this.src='../../../assets/icons/TPE.png'" width="100" height="100">
                </div>
                <div class="col-auto">
                    <h2 class="color-primary-700 detail-link">{{getName(equipmentForm.value)}}</h2>
                    <div>
                        <div class="mat-h2" *ngIf="equipmentForm.get('rental_equipment_amount').value">{{equipmentForm.get('rental_equipment_amount').value*1 | currency}} HT/Mois</div>
                        <div class="mat-h2" *ngIf="equipmentForm.get('purchase_equipment_amount').value">{{equipmentForm.get('purchase_equipment_amount').value+equipmentForm.get('yearly_maintenance_amount').value+equipmentForm.get('yearly_subscription3g_amount').value | currency}} HT</div>

                    </div>
                    <div *ngIf="false" class="description" [innerHTML]="equipmentForm.get('description').value"></div>
                </div>
            </div>
            <div class="d-flex mat-h4"><mat-icon class="align" [svgIcon]="'suivi-commande:combined-shape'"></mat-icon>{{equipmentForm.get('communication_type').value}}</div>
            <div class="d-flex mat-h4"><mat-icon class="align" [svgIcon]="'suivi-commande:icon-contrat'"></mat-icon>{{getContractLabel(equipmentForm.value)}}</div>
            <div class="d-flex mat-h4"><mat-icon class="align" [svgIcon]="'suivi-commande:shape'"></mat-icon>{{getInstallationLabel(equipmentForm.value)}}</div>
            <div>
                <a mat-stroked-button class="mr-5 color-primary-200 border-color-primary-200"
                *ngIf="!form.parent.get('additional_order').value"
                (click)="removeEquipment()">Retour</a>
            </div>
        </div>
        <div class="col-8 p-0 m-0 mt-3">
            <app-softwares-form [(formArray)]="equipmentForm.controls.softwares" [refOffer]="equipmentForm.get('id_offer')" [refSoftwares]="equipmentForm.get('ref_softwares').value" [refProductRequirements]="refProductRequirements" [refProductIncompatibilities]="refProductIncompatibilities"></app-softwares-form>
            <app-accessories-form [(formArray)]="equipmentForm.controls.accessories" [refAccessories]="equipmentForm.get('ref_accessories').value"></app-accessories-form>
        </div>
        <div class="col-4 d-flex flex-column align-items-center p-0 pl-4 m-0 mt-3">
            <mat-card class="d-flex flex-column h-auto w-100 shadow bnpp-title-shadow p-0">
                <mat-mat-card-header class="d-flex align-items-center card-form-logiciel bnpp-title">
                    <mat-card-title class="color-primary-700">Prix</mat-card-title>
                </mat-mat-card-header>
                <div class="m-2">
                    <app-number-form *ngIf="equipmentForm.get('quantity') && !form.parent.get('additional_order').value" label="Quantité" [min]="1" [max]="equipmentForm.get('equipment_change_request').value?countChangeRequestLeft():null" formControlName="quantity"></app-number-form>
                    <div class="total-row d-flex justify-content-between p-2" *ngIf="equipmentForm.get('equipment_change_request').value && hasPossibleChangeRequest(equipmentForm.getRawValue()).length">
                        <div><b>Changement de gamme</b></div>
                        <mat-slide-toggle class="px-3" (change)="editChangeRequest($event.checked)" [checked]="equipmentForm.get('equipment_change_request').value"></mat-slide-toggle>
                        <mat-icon color="primary" [matTooltip]="changeRequestTooltip(equipmentForm.getRawValue())" matTooltipClass="primary">help_outline</mat-icon>
                    </div>
                    <!-- {{changeRequestText(equipmentForm.getRawValue())}} -->
                    <div class="total-row d-flex justify-content-between p-2" *ngIf="equipmentForm.get('rental_equipment_total').value">
                        <div><b>Total location</b> (HT/Mois)</div>
                        <div class="color-warn-100"><b>{{
                            equipmentForm.get('rental_equipment_total').value
                            | currency
                        }}</b></div>
                    </div>
                    <div class="total-row d-flex justify-content-between p-2" *ngIf="equipmentForm.get('purchase_equipment_total').value">
                        <div><b>Total achat</b> (HT)</div>
                        <div class="color-warn-100"><b>{{
                            equipmentForm.get('purchase_equipment_total').value
                            +equipmentForm.get('yearly_maintenance_total').value
                            +equipmentForm.get('yearly_subscription3g_total').value
                            | currency
                        }}</b></div>
                    </div>
                    <div class="total-row d-flex justify-content-between p-2" *ngIf="equipmentForm.get('purchase_softwares_total').value || form.parent.get('additional_order').value">
                        <div><b>Total logiciels</b> (HT)</div>
                        <div class="color-warn-100"><b>{{
                            equipmentForm.get('purchase_softwares_total').value
                            | currency
                        }}</b></div>
                    </div>
                    <div class="total-row d-flex justify-content-between p-2" *ngIf="equipmentForm.get('purchase_services_total').value">
                        <div><b>Total accessoires</b> (HT)</div>
                        <div class="color-warn-100"><b>{{
                            equipmentForm.get('purchase_services_total').value
                            | currency
                        }}</b></div>
                    </div>
                    <div class="total-row d-flex justify-content-between p-2" *ngIf="false && equipmentForm.get('total').value">
                        <div><b>Total</b> (HT)</div>
                        <div class="color-warn-100"><b>{{
                            equipmentForm.get('total').value
                            | currency
                        }}</b></div>
                    </div>
                    <div class="total-row d-flex justify-content-center mt-2 p-2">
                        <button mat-button class="background-color-primary-50 opposite-color-foreground-base" type="submit" (click)="addEquipment()">
                            <mat-icon>shopping_cart</mat-icon>
                            Ajouter au panier
                        </button>
                    </div>
                </div>
            </mat-card>
        </div>
    </form>
</ng-template>
